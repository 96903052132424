import {
  Box,
  Button,
  FormLabel,
  HStack,
  Img,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  SimpleGrid,
  Stack,
  Text,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { COLORS } from "./colors";
import { useState } from "react";
import { AiOutlineCloudUpload } from "react-icons/ai";

export const FamilyModal = ({
  requests,
  setRequests,
  serviceCharge,
  mainFormValues,
  clearMainForm,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [familyMemberMedia, setFamilyMemberMedia] = useState(null);
  const [familyMembers, setFamilyMembers] = useState([]);
  const [formValues, setFormValues] = useState({
    name: "",
    email: "",
    phoneNumber: "",
    houseNumber: "",
    street: "",
    media: "",
    totalAmount: 3000,
  });
  const [errors, setErrors] = useState({
    mainName: "",
    email: "",
    phoneNumber: "",
    houseNumber: "",
    street: "",
  });
  const toast = useToast();

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (value !== "") {
      // Name validation
      if (name === "name") {
        const nameRegex = /^[a-zA-Z ]+$/;
        if (!nameRegex.test(value)) {
          setErrors((prevErrors) => ({
            ...prevErrors,
            name: "Please enter a valid name",
          }));
          return;
        } else {
          setErrors((prevErrors) => ({ ...prevErrors, name: "" }));
        }
      }
    }
    setFormValues((prev) => ({ ...prev, [name]: value }));
  };

  const handleFamilyMemberImageUpload = (event) => {
    if (!event.target.files) return;
    const file = event.target.files[0];
    const acceptedImageTypes = ["image/jpeg", "image/png", "image/jpg"];

    if (!acceptedImageTypes.includes(file.type)) {
      toast({
        title: "Invalid file type.",
        description: "Please upload a valid image file (JPEG, PNG, JPG).",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      event.target.value = null;
      return;
    }
    setFamilyMemberMedia(file);
    setFormValues((prev) => ({ ...prev, media: file }));
  };

  const addFamilyMember = () => {
    if (
      !formValues.name ||
      !formValues.email ||
      !formValues.phoneNumber ||
      !formValues.media
    ) {
      toast({
        title: "Incomplete details.",
        description: "Please fill in all the details.",
        status: "warning",
        duration: 3000,
        bg: "white",
        isClosable: true,
      });
      return;
    }

    const newFamilyMember = {
      type: "Family",
      ...formValues,
      passport: familyMemberMedia,
      totalAmount: serviceCharge,
    };

    setFamilyMembers((prev) => [...prev, newFamilyMember]);
    clearForm();
  };

  const clearForm = () => {
    setFamilyMemberMedia(null); // Reset family member media
    setFormValues({
      name: "",
      email: "",
      phoneNumber: "",
      houseNumber: "",
      street: "",
      totalAmount: 3000,
    });
  };

  const handleAddAll = () => {
    // if (
    //         formValues?.mainName ||
    //         formValues?.email ||
    //         formValues?.phoneNumber ||
    //         formValues?.media
    //       ) {
    //         const newFamilyMember = {
    //           type: "Family",
    //           ...formValues,
    //           totalAmount: serviceCharge,
    //         };

    //         setRequests((prev) => [...prev, newFamilyMember]);
    //         clearForm();

    //       }
    if (
      familyMembers.length < 1 ||
      formValues?.mainName ||
      formValues?.email ||
      formValues?.phoneNumber ||
      formValues?.media
    ) {
      const newFamilyMember = {
        type: "Family",
        ...formValues,
        passport: familyMemberMedia,
        totalAmount: serviceCharge,
      };
      setFamilyMembers((prev) => [...prev, newFamilyMember]);
      setRequests((prevRequests) => [...prevRequests, newFamilyMember]);
      setFamilyMembers([]);
      clearForm();
      onClose();
      return;
    }

    if (familyMembers.length > 0) {
      setRequests((prevRequests) => [...prevRequests, ...familyMembers]);
      setFamilyMembers([]);
      onClose();
    } else {
      alert("No family members added.");
    }
  };

  const inputStyle = {
    bg: "#d5f2de",
    rounded: "lg",
    color: "black",
    h: "50px",
  };

  return (
    <>
      <Button
        size={"lg"}
        w={["100%", "100%"]}
        onClick={() => {
          if (
            mainFormValues.mainName ||
            mainFormValues.email ||
            mainFormValues.phoneNumber ||
            mainFormValues.media
          ) {
            setRequests((prev) => [...prev, { ...mainFormValues }]);
            clearMainForm();
          }
          onOpen();
        }}
        borderColor={COLORS.green}
        color={COLORS.green}
        border={`2px solid ${COLORS.green}`}
      >
        Add Family Members
      </Button>
      <Modal isOpen={isOpen} onClose={onClose} size={"3xl"}>
        <ModalOverlay />
        <ModalContent py={"30px"} bg={"white"}>
          <ModalHeader color={"black"}>Add Family Members</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {/* Form for adding a family member */}
            <Stack spacing="20px">
              <SimpleGrid columns={{ base: 1, md: 2 }} spacing={4}>
                <Box color={"black"}>
                  <Text fontSize={"18px"}>Name</Text>
                  <Input
                    {...inputStyle}
                    type="text"
                    // pattern="[a-zA-Z ]+"
                    required
                    placeholder="Enter Name"
                    _placeholder={{ color: "#000" }}
                    name="name"
                    border="none"
                    borderBottom="1px solid #ccc"
                    borderRadius="10px"
                    value={formValues.name}
                    onChange={handleChange}
                  />
                  {errors.name && <Text color="red">{errors.name}</Text>}
                </Box>
                <Box color={"black"}>
                  <Text fontSize={"18px"}>Email</Text>
                  <Input
                    {...inputStyle}
                    type="email"
                    required
                    placeholder="Enter Email"
                    _placeholder={{ color: "#000" }}
                    name="email"
                    border="none"
                    borderBottom="1px solid #ccc"
                    borderRadius="10px"
                    value={formValues.email}
                    onChange={(e) =>
                      setFormValues((prev) => ({
                        ...prev,
                        email: e.target.value,
                      }))
                    }
                    onBlur={(e) => {
                      const emailRegex =
                        /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
                      if (!emailRegex.test(e.target.value)) {
                        setErrors((prevErrors) => ({
                          ...prevErrors,
                          email: "Please enter a valid email address",
                        }));
                      } else {
                        setErrors((prevErrors) => ({
                          ...prevErrors,
                          email: "",
                        }));
                      }
                    }}
                  />

                  {errors.email && <Text color="red">{errors.email}</Text>}
                </Box>
                <Box color={"black"}>
                  <Text fontSize={"18px"}>Phone Number</Text>
                  <Input
                    {...inputStyle}
                    type="tel"
                    required
                    placeholder="Enter Phone Number"
                    _placeholder={{ color: "#000" }}
                    name="phoneNumber"
                    border="none"
                    borderBottom="1px solid #ccc"
                    borderRadius="10px"
                    value={formValues.phoneNumber}
                    onChange={(e) =>
                      setFormValues((prev) => ({
                        ...prev,
                        phoneNumber: e.target.value,
                      }))
                    }
                    onBlur={(e) => {
                      const phoneRegex =
                        /^[+]?[(]?[0-9]{1,4}[)]?[-\s.]?[0-9]{1,3}[-\s.]?[0-9]{1,4}[-\s.]?[0-9]{1,9}$/;
                      if (!phoneRegex.test(e.target.value)) {
                        setErrors((prevErrors) => ({
                          ...prevErrors,
                          phoneNumber: "Please enter a valid phone number",
                        }));
                      } else {
                        setErrors((prevErrors) => ({
                          ...prevErrors,
                          phoneNumber: "",
                        }));
                      }
                    }}
                  />
                  {errors.phoneNumber && (
                    <Text color="red">{errors.phoneNumber}</Text>
                  )}
                </Box>
                <Box color={"black"}>
                  <Text fontSize={"18px"}>House Number</Text>
                  <Input
                    {...inputStyle}
                    placeholder="Enter House Number"
                    _placeholder={{ color: "#000" }}
                    name="houseNumber"
                    value={formValues.houseNumber}
                    onChange={handleChange}
                  />
                </Box>
                <Box color={"black"}>
                  <Text fontSize={"18px"}>Street</Text>
                  <Input
                    {...inputStyle}
                    placeholder="Enter Street"
                    _placeholder={{ color: "#000" }}
                    name="street"
                    value={formValues.street}
                    onChange={handleChange}
                  />
                </Box>
              </SimpleGrid>
            </Stack>

            <Stack mt={5}>
              <FormLabel htmlFor="familyMemberMedia">
                <HStack
                  cursor="pointer"
                  bg={COLORS.green}
                  p={"10px"}
                  rounded={"lg"}
                  w={"220px"}
                >
                  <Text color={"white"} fontWeight="500">
                    Upload Passport Photo
                  </Text>
                  <AiOutlineCloudUpload size={"1.5rem"} color={"white"} />
                </HStack>
                <Text color={"black"} fontSize={["14px", "16px"]}>
                  Ensure that the photo is clear, taken in a bright place and
                  your face is facing front, and fully visible.
                </Text>
                <Input
                  type="file"
                  display="none"
                  id="familyMemberMedia"
                  onChange={handleFamilyMemberImageUpload}
                />
              </FormLabel>
              {familyMemberMedia && (
                <Img
                  w="70px"
                  h="70px"
                  m="2"
                  borderRadius={"5px"}
                  src={URL.createObjectURL(familyMemberMedia)}
                  alt="Passport"
                />
              )}
            </Stack>

            {familyMembers.length > 0 && (
              <Box mt="20px">
                <Text fontSize="20px" fontWeight="bold">
                  Family Members:
                </Text>
                {familyMembers.map((member, index) => (
                  <Box key={index} bg="#f7f7f7" p="10px" borderRadius="8px">
                    <Text>
                      Name: {member.name}, Email: {member.email}, Phone:{" "}
                      {member.phoneNumber}, House Number: {member.houseNumber},
                      Street: {member.street}, Total Amount: &#8358;
                      {serviceCharge}
                    </Text>
                  </Box>
                ))}
              </Box>
            )}
          </ModalBody>
          <ModalFooter>
            <Button bg={COLORS.green} onClick={addFamilyMember} mr={3}>
              Add More
            </Button>
            <Button
              onClick={handleAddAll}
              borderColor={COLORS.green}
              color={COLORS.green}
              border={`1px solid ${COLORS.green}`}
            >
              Continue to Payment
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
