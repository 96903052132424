import React, { useState } from "react";
import { Button, Spinner } from "@chakra-ui/react";
import axios from "axios";
import { COLORS } from "./colors";

const PaystackPayment = ({
  requests,
  totalAmount,
  onSuccess,
  formValues,
  addRequest,
  clearForm,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const sumTotalAmount = requests?.reduce(
    (acc, obj) => acc + parseFloat(obj?.totalAmount),
    0
  );

  const handlePayment = async () => {
    if (!addRequest()) return;
    // setIsLoading(true);

    // const { data } = responses[0];

    // const { authorization_url, reference } = data.result.data.payment;

    const handler = window.PaystackPop.setup({
      // key: "pk_live_154cdde00eeb226bac92b9267598413bee766dcc",
      key: "pk_test_664bdbe1d71f09f11a4beba7f61ed26df6f16e60",
      email:
        formValues?.email ||
        requests?.[0]?.email ||
        requests?.[1]?.email ||
        requests?.[2]?.email,
      amount: requests?.length > 0 ? sumTotalAmount * 100 : totalAmount * 100,
      currency: "NGN",
      // ref: reference,
      callback: function () {
        if (requests?.length > 0) {

          const allRequest = requests?.map(
            (data) =>
              new Promise((resolve, reject) => {
                const formData = new FormData();
                // return;
                const {
                  mainName,
                  name,
                  media,
                  email,
                  phoneNumber,
                  houseNumber,
                  street,
                  totalAmount,
                  passport,
                } = data || {};
                formData.append("name", name || mainName);
                formData.append("passport", media || passport);
                formData.append("email", email);
                formData.append("phoneNumber", phoneNumber);
                formData.append("houseNumber", houseNumber);
                formData.append("street", street);
                formData.append("totalAmount", totalAmount);

                axios
                  .post(
                    "https://backend.myestate.ng/api/v1/card/request",
                    formData,
                    {
                      headers: {
                        "Content-Type": "multipart/form-data",
                      },
                    }
                  )
                  .then((res) => resolve(res))
                  .catch((error) => {
                    reject(
                      error?.result?.message ||
                        "A card has been requested with same name"
                    );
                  });
              })
          );
          Promise.all(allRequest)
            .then((res) => {
              alert(res?.[0]?.data?.result?.message);
            })
            .catch((error) => alert(error));
        }

        const formData = new FormData();
        // return;
        const {
          mainName,
          media,
          email,
          phoneNumber,
          houseNumber,
          street,
          // totalAmount,
        } = formValues || {};
        if (
          !mainName ||
          !media ||
          !email ||
          !phoneNumber ||
          !houseNumber ||
          !street
        ) {
          // alert("Some fields are missing");
          return;
        }
        formData.append("name", mainName);
        formData.append("passport", media);
        formData.append("email", email);
        formData.append("phoneNumber", phoneNumber);
        formData.append("houseNumber", houseNumber);
        formData.append("street", street);
        formData.append("totalAmount", totalAmount);
        axios
          .post("https://backend.myestate.ng/api/v1/card/request", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then((res) => {
            alert(res?.result?.data?.message);
          })
          .catch((error) => {
            alert(
              error?.response?.data.result?.message ||
                "A card has been requested with same name"
            );
          });
      },
      onClose: function () {
        alert("Payment cancelled");
      },
    });
    handler.openIframe();
  };


  return (
    <Button
      mb={"100px"}
      mt={"20px"}
      bg={COLORS.green}
      color={"white"}
      w={"200px"}
      size={"lg"}
      _hover={{ backgroundColor: `${COLORS.green}` }}
      onClick={handlePayment}
      disabled={isLoading}
    >
      {isLoading ? <Spinner size="sm" color="white" mr={2} /> : "Pay Now"}
    </Button>
  );
};

export default PaystackPayment;
